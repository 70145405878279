<script setup lang="ts">
import gsap from 'gsap';

import { BasicShadowMap, NoToneMapping, SRGBColorSpace } from 'three';

// import { Sky } from 'three-stdlib';

import { type TresContext, TresCanvas } from '@tresjs/core';

// import { TresLeches, useControls } from '@tresjs/leches';
// import '@tresjs/leches/styles';

import { Ocean, Sky, Html } from '@tresjs/cientos';

// import { email, emailLink } from 'src/data/contacts';

import { useYandexMetrika } from 'yandex-metrika-vue3';

import Lenis from 'lenis';

const yandexMetrika = useYandexMetrika();
const router = useRouter();
const route = useRoute();
// const { onLoop } = useRenderLoop();

const waterLikePostEffectDS3Ref = ref();

const gl = {
  // powerPreference: 'high-performance',
  antialias: true,
  // stencil: false,
  // depth: false,
  windowSize: true,
  clearColor: '#c5aaff',
  // shadows: true,
  alpha: false,
  shadowMapType: BasicShadowMap,
  outputColorSpace: SRGBColorSpace,
  toneMapping: NoToneMapping,
};

// const { onLoop } = useRenderLoop();

const cameraZoomDefault = 0.5;
// const cameraZoomMax = 1;

const wheel = ref(0);

const initLenis = () => {
  const lenis = new Lenis({
    // wrapper: el,
    // content: el.firstChild as HTMLElement,
    //
    // smoothWheel: true,
    // touchMultiplier: 2,
    infinite: true,
    // syncTouch: true,
    // duration: 1.2,
    // easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
  });

  function raf(time: number) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);

  lenis.on('scroll', (e: Lenis) => {
    const {
      // direction, animatedScroll,
      velocity,
      // lastVelocity, actualScroll
    } = e;

    wheel.value += velocity;

    const zoom = 1 - velocity / 10;

    if (zoom >= 0.1 && zoom <= 2) {
      gsap.set(perspectiveCameraRef.value, {
        zoom,
      });
    }

    // perspectiveCameraRef.value.zoom = Math.max(0, wheel.value / 10);

    // if (
    //   perspectiveCameraRef.value.zoom >= 0.1 &&
    //   perspectiveCameraRef.value.zoom <= 2
    // ) {
    //   perspectiveCameraRef.value.zoom =wheel.value;
    // }
    // const elevation = skyPropsDefaults().elevation + velocity;

    // if (elevation >= 0 && elevation <= 3) {
    //   gsap.set(skyProps, {
    //     elevation,
    //   });
    // }

    // gsap.to(skyProps, {
    //   azimuth: 178,
    //   duration: durationLeave,
    //   ease: 'power1.out',
    // });
  });
};

const canvasRef = ref();

let context: TresContext;

const skyPropsDefaults = () => ({
  turbidity: 1,
  rayleigh: 2,
  elevation: 1.2,
  azimuth: 183,
  mieCoefficient: 0.005,
  mieDirectionalG: 0.8,
  distance: 10000,
});

const skyProps = reactive(skyPropsDefaults());

onMounted(async () => {
  await nextTick();

  context = canvasRef.value.context;

  useTreCameraFollow(context, 0.4);

  // initLenis();
});

const perspectiveCameraRef = ref();

// < Camera Zoom

let isMouseOver = false;
// const durationOver = 1.6;
const durationLeave = 2;

// const cameraPosZ = 100;
// const htmlPosZ = 90;

const mouseenter = () => {
  if (isMouseOver) return;

  isMouseOver = true;

  gsap.to(perspectiveCameraRef.value, {
    zoom: 1,
    duration: durationLeave,
    ease: 'power1.out',
  });

  gsap.to(skyProps, {
    elevation: 2,
    duration: durationLeave,
    ease: 'power1.out',
  });

  gsap.to(skyProps, {
    azimuth: 179,
    duration: durationLeave,
    ease: 'power1.out',
  });

  // gsap.to(perspectiveCameraRef.value.position, {
  //   z: cameraPosZ * 0.9,
  //   duration: durationOver,
  //   ease: 'power2.out',
  // });

  // gsap.to(centerGroupRef.value.position, {
  //   z: htmlPosZ * 0.9,
  //   duration: durationOver,
  //   ease: 'power2.out',
  // });
};

const mouseleave = () => {
  isMouseOver = false;

  gsap.to(perspectiveCameraRef.value, {
    zoom: cameraZoomDefault,
    duration: durationLeave,
    ease: 'power1.out',
  });

  gsap.to(skyProps, {
    elevation: skyPropsDefaults().elevation,
    duration: durationLeave,
    ease: 'power1.out',
  });

  gsap.to(skyProps, {
    azimuth: skyPropsDefaults().azimuth,
    duration: durationLeave,
    ease: 'power1.out',
  });

  // gsap.to(perspectiveCameraRef.value, {
  //   zoom: 2,
  //   duration: durationLeave,
  //   ease: 'power1.out',
  // });

  // gsap.to(perspectiveCameraRef.value.position, {
  //   z: cameraPosZ,
  //   duration: durationLeave,
  //   ease: 'power1.out',
  // });

  // gsap.to(centerGroupRef.value.position, {
  //   z: htmlPosZ,
  //   duration: durationLeave,
  //   ease: 'power1.out',
  // });
};

// >

const goToProjects = () => {
  // Click to btn

  yandexMetrika.reachGoal('click_btn_view_works');

  const duration = 0.6;

  gsap.to(perspectiveCameraRef.value.rotation, {
    x: Math.PI * 0.5,
    duration,
    // onComplete() {
    // router.push('/projects');
    // },
  });

  setTimeout(() => router.push('/projects'), duration * 1000);
};

// onUnmounted(() => context.renderer.value.dispose());
</script>

<template lang="pug">
//- TresLeches(
  v-if="isShowSettings"
  class="important-fixed important-left-2 important-w-120")
TresCanvas(
  ref="canvasRef"
  v-bind="gl"
  transparents
  render-mode="manual")
  //- Levioso(
    :speed="2"
    :rotation-factor="0.005"
    :float-factor="0")
  TresPerspectiveCamera(
      ref="perspectiveCameraRef"
      :position="[30,30,100]"
      :rotate-x="-Math.PI*0.5"
      :fov="25"
      :zoom="cameraZoomDefault")
  //- OrbitControls(
    :enable-zoom="false")



  //- TresGridHelper(
    :args="[100, 100]"
    :position="[0, 0, -8]"
    :rotate-x="-0.5 * Math.PI")
  // Light
  //- TresHemisphereLight(
    :intensity="0.5"
    ground-color="black")
  //- TresAmbientLight(
    :intensity="1")
  //- TresDirectionalLight(
    :intensity="3"
    :position="[-5,5,5]")

  // Post
  WaterLikePostEffectDS3(ref="waterLikePostEffectDS3Ref")

  Sky(v-bind="skyProps")
  Suspense
    Ocean(
      ref="oceanRef"
      :position="[0,-0.8,0]"
      :rotation-x="- Math.PI / 2"
      :sun-color="0xffffff"
			:water-color="0x001e0f"
			:distortion-scale="3.7")
  TresGroup(
    ref="centerGroupRef"
    :position="[30, 29, 90]"
    :rotation-x="-Math.PI*0.035")
    Html(
      v-if="route.path === '/'"
      center
      wrapper-class="tres-html-center"
      :z-index-range="[1,2]")
      .html-center().w-100vw.flex.flex-center
        .html-center__mouseover(
          @mouseenter="mouseenter"
          @mouseleave="mouseleave").pa-16
          // Text
          .flex.flex-center
            .html-center__text(style="opacity: .7;")
              h5 дизайн и разработка
              h1.alegreya-400.text-black
                | Pt
                span.italic B
                | urg
              h5 арт студия
          // Button
          .flex.flex-center.min-w-320px
            .pa-8
              TreBtn(
                label="Смотреть работы"
                color="white"
                text-color="black"
                @click="goToProjects")
            //- .w-full.text-center
              EmailLink.text-black.opacity-60

</template>
