<script setup lang="ts">
// useMeta(() => {
//   return {
//     title: 'PtBurg',
//   };
// });

// useMeta(() => ({
//   title: 'PtBurg',
//   meta: {
//     // Основные мета-теги
//     description: { name: 'description', content: 'Интернет это искусство' },
//     // keywords: { name: 'keywords', content: articleKeywords.value },

//     // Open Graph
//     ogTitle: { property: 'og:title', content: 'PtBurg' },
//     ogDescription: {
//       property: 'og:description',
//       content: 'Интернет это искусство',
//     },
//     ogImage: {
//       property: 'og:image',
//       content: 'https://ptburg.com/og-image.png',
//     },
//     // ogUrl: { property: 'og:url', content: articleUrl.value },
//     // ogType: { property: 'og:type', content: 'article' },
//     // ogSiteName: { property: 'og:site_name', content: siteName.value },
//     // ogLocale: { property: 'og:locale', content: locale.value },
//     // ogPublishedTime: {
//     //   property: 'og:published_time',
//     //   content: articlePublishedDate.value,
//     // },
//     // ogModifiedTime: {
//     //   property: 'og:modified_time',
//     //   content: articleUpdatedDate.value,
//     // },

//     // Twitter Cards
//     // twitterCard: { name: 'twitter:card', content: 'summary_large_image' },
//     // twitterTitle: { name: 'twitter:title', content: articleTitle.value },
//     // twitterDescription: {
//     //   name: 'twitter:description',
//     //   content: articleDescription.value,
//     // },
//     // twitterImage: { name: 'twitter:image', content: articleThumbnail.value },
//     // twitterSite: { name: 'twitter:site', content: twitterSite.value },
//     // twitterCreator: { name: 'twitter:creator', content: twitterCreator.value },

//     // Структурированные данные для статей
//     // articlePublishedTime: {
//     //   property: 'article:published_time',
//     //   content: articlePublishedDate.value,
//     // },
//     // articleModifiedTime: {
//     //   property: 'article:modified_time',
//     //   content: articleUpdatedDate.value,
//     // },
//     // articleAuthor: { property: 'article:author', content: articleAuthor.value },
//     // articleSection: {
//     //   property: 'article:section',
//     //   content: articleSection.value,
//     // },

//     // Canonical URL
//     // canonical: { rel: 'canonical', href: articleUrl.value },

//     // Мобильные мета-теги
//     // viewport: {
//     //   name: 'viewport',
//     //   content: 'width=device-width, initial-scale=1.0',
//     // },
//     // mobileWebAppCapable: {
//     //   name: 'apple-mobile-web-app-capable',
//     //   content: 'yes',
//     // },
//     // themeColor: { name: 'theme-color', content: themeColor.value },

//     // Robots
//     robots: { name: 'robots', content: 'index, follow' },

//     // Дополнительные мета-теги для SEO
//     // revisitAfter: { name: 'revisit-after', content: '7 days' },
//     // googleSiteVerification: {
//     //   name: 'google-site-verification',
//     //   content: googleVerificationCode.value,
//     // },
//   },
// }));

const route = useRoute();
</script>

<template lang="pug">
q-page.overflow-hidden
  Suspense
    TreIndex
  .contacts(v-if="route.name === 'contacts'")
    //- q-form
    EmailLink
    TelegramLink
</template>
